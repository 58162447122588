import './config/jquery'
import './config/fontawesome'
import './config/bootstrap'

import './main.scss'

import './navbar.js'
import './cart.js'

import 'bootstrap-input-spinner'
import './numberInput.js'
import './cookieconsent.js'

if (document.querySelector('.bookingForm')) {
  // eslint-disable-next-line no-unused-expressions
  import('./roots/bookingForm')
}

if (document.querySelector('#checkout')) {
  // eslint-disable-next-line no-unused-expressions
  import('./roots/checkout')
}

if (document.querySelector('#giftCheckout')) {
  // eslint-disable-next-line no-unused-expressions
  import('./roots/giftCheckout')
}

if (document.querySelector('.product-image-gallery')) {
  // eslint-disable-next-line no-unused-expressions
  import('./productGallery')
}


if(document.querySelector("#courseFilterContainer")) {
  import('./roots/courseFilter')
}
